import React, { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Button3 from './Button3';
import { ContactFormContext } from '../context/ContactFormContext';
import Api from '../API/Api';

export default function ContactFormWrap() {
    const { showModal, close } = useContext(ContactFormContext);

    const { queryApi } = Api();

    const [queryData, setQueryData] = useState({
        name: '',
        email: '',
        mobile: '',
    })
    console.log(queryData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setQueryData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        queryApi.post('/auth/postQueryData', { ...queryData }).then(res => {
            console.log(res);
        }).catch(e => {
            console.log(e);
        })
        setQueryData({
            name: '',
            email: '',
            mobile: ''
        })
    }
    return (
        <CSSTransition
            in={showModal}
            timeout={500}
            classNames="modal"
            unmountOnExit
        >
            <div className='fixed inset-0 flex items-center justify-center z-10 '>
                <div className='h-auto rounded-xl w-full lg:w-2/5' data-aos-duration="500">
                    <div className='justify-center'>
                        <div className='border-0 shadow-2xl relative flex flex-col min-w-0 bg-white bg-clip-border rounded'>
                            <div className='p-0'>
                                <div className='mx-0'>
                                    <div className='p-6 w-200px'>
                                        <div className='mb-2'>
                                            <button className='absolute right-4 top-2 font-bold' onClick={() => close()}>
                                                X
                                            </button>
                                            <h3 className='text-[#020f66c6] font-bold text-2xl'>
                                                Please enter your credentials
                                            </h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className='mb-4'>
                                                <label htmlFor='name' className='text-[#7e7e7e] cursor-pointer text-sm font-normal'>Name:</label>
                                                <input
                                                    type="text"
                                                    className='block w-full py-[0.375rem] px-[0.75rem] text-base leading-6 
                                                    text-[#495057] bg-white bg-clip-padding border border-[#ced4da]'
                                                    id="name"
                                                    name='name'
                                                    value={queryData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor='email' className='text-[#7e7e7e] cursor-pointer text-sm font-normal'>Email ID:</label>
                                                <input
                                                    type="email"
                                                    className='block w-full py-[0.375rem] px-[0.75rem] text-base leading-6 text-[#495057]
                                                  bg-white bg-clip-padding border border-[#ced4da]'
                                                    id="email"
                                                    name='email'
                                                    value={queryData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor='number' className='text-[#7e7e7e] cursor-pointer text-sm font-normal'>Mobile No:</label>
                                                <input
                                                    type="number"
                                                    className='block w-full py-[0.375rem] px-[0.75rem] text-base leading-6 text-[#495057]
                                                   bg-white bg-clip-padding border border-[#ced4da]'
                                                    id="mobile"
                                                    name='mobile'
                                                    value={queryData.mobile}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <input type="hidden" id="token" name="token"></input>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Button3 text='log in' />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}
