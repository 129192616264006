import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import QuizInstruction from '../components/QuizInstruction'
import QuizNav from '../components/QuizNav'
import QuizQuestions from '../components/QuizQuestions'
import QuizScore from '../components/QuizScore'
import QuizTimer from '../components/QuizTimer'
import { OverlayContext } from '../context/OverlayContext'
import QuizFinish from '../components/QuizFinish'
import { useIdleLogout } from '../hooks/useIdleLogout'
import Api from '../API/Api'

export default function Quiz() {
    const { start, finish, score, } = useContext(OverlayContext);
    const {http} = Api()
    useIdleLogout();
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {  
            localStorage.setItem('refreshRedirect', 'true');
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const refreshRedirect = localStorage.getItem('refreshRedirect');

        if (refreshRedirect) {
            const userId = JSON.parse(localStorage.getItem('user')).id;
            window.alert("Your test has been canceled");
            http.post(`/auth/deleteTest`, {userId}).then((res) => {
                console.log(res);
            }).catch((error) => {
                console.log(error);
            })
            localStorage.removeItem('refreshRedirect'); 
            navigate('/pages/dashboard'); 
        }
    }, [navigate]);

    return (
        <div className='relative'>
            <QuizNav />
            <div className='container mx-auto p-2'>
                <div className='flex flex-wrap'>
                    <div className='w-full md:w-1/4 px-4'>
                        <QuizTimer />
                        <QuizScore score={score} />
                    </div>
                    <div className='w-full md:w-3/4 px-4'>
                        {finish ?
                            <>
                                {start ?
                                    <QuizQuestions />
                                    :
                                    <QuizInstruction />
                                }
                            </>
                            :
                            <QuizFinish />
                        }
                    </div>
                </div>
            </div>
            <div className='text-pink py-2 my-2 text-center w-full block sm:text-lg font-semibold md:hidden'>
                <span className='mr-1'>
                    <i className="fa-solid fa-marker fa-lg"></i>
                </span>
                <Link>
                    View Instructions
                </Link>
            </div>
        </div>
    )
}
