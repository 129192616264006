import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../API/Api';

export const OverlayContext = createContext()

export function OverlayProvider({ children }) {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    // const [attempts, setAttempt] = useState([])
    const [id, setId] = useState(0);
    const [email, setEmail] = useState('');
    const [bool, setBool] = useState(false);
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [questionBool, setQuestionBool] = useState(false);
    const { http } = Api();
    const [popupState, setPopupState] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null)
    console.log(currentQuestion);
    console.log(popupState);
    console.log(data);

    const showPopup = () => {
        setPopupState(true);
    }
    const hidePopup = () => {
        setPopupState(false);
    }
    const [transaction, setTransaction] = useState({
        username: '',
        login_date_time: 0,
        logout_date_time: 0,
        flag: null
    })
    console.log(data);

    const [latestTransaction, setLatestTransaction] = useState(0);

    const stringifyTrans = JSON.stringify(transaction)
    if (localStorage.getItem('transaction') === null) {
        localStorage.setItem('transaction', stringifyTrans);

    }
    const flagVal = JSON.parse(localStorage.getItem('transaction'))
    if (transaction.username === "" && transaction.login_date_time === 0 && flagVal.flag === 0) {
        const transactionReload = JSON.parse(localStorage.getItem('transaction'))
        console.log(transactionReload);

        setTransaction(transactionReload);

    }
    console.log(JSON.stringify(transaction));
    console.log(transaction);
    console.log(bool);

    const [showOverLay, setShowOverlay] = useState(false)
    const [start, setStart] = useState(false);
    const [finish, setFinish] = useState(true);

    const [time, setTime] = useState(600);
    const [timeInterval, setTimeInterval] = useState(null);

    const scoreDataArr = [
        { bgColor: 'lightBlue', text: 'Total Marks of paper', value: 0 },
        { bgColor: '#95d5b2', text: 'Total Marks of correct ans', value: 0 },
        { bgColor: 'blue', text: 'Total attempts', value: 0 },
        { bgColor: 'rgb(0, 148, 32)', text: 'Total of Correct attempts', value: 0 },
        { bgColor: 'rgb(201, 0, 0)', text: 'Total of Wrong attempts', value: 0 },
        { bgColor: 'rgb(236, 152, 26)', text: 'Total questions Skipped', value: 0 },
        { bgColor: '#9d4edd', text: 'Bookmark', value: 0 },
    ]
    const [score, setScore] = useState(scoreDataArr);

    const navigate = useNavigate();

    const location = useLocation();

    if (location.key === 'default') {
        navigate('/')
    }
    const [loading, setLoading] = useState(false);
    const handleStart = () => {
        setStart(!start)
    };

    const handleQuestion = () => {
        setQuestionBool(true);
    }

    useEffect(() => {
        if (questionBool) {
            const email = JSON.parse(localStorage.getItem('user')).id;
            console.log(email);
            console.log(currentQuestion);
            http.get(`/auth/question/${email}`).then(response => {
                console.log(response);
                // setData(response.data.questions);
                const addColors = response.data.questions.map((item) => {
                    return { ...item, backgroundColor: "#e5e5e5", color: "#133382", bookmark: false, isChecked: null }
                })
                setData(addColors)
                setQuestionBool(false)
            })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [questionBool]);

    useEffect(() => {
        const updateCurrentColor = data.map((item) => {
            if (item.isChecked === null && currentQuestion === item.id) {
                return { ...item, backgroundColor: "#f54a8d",color: "white", isChecked: false }
            }
            else if (currentQuestion === item.id) {
                return { ...item, backgroundColor: "#f54a8d", color: "white" }
            }
            else if (item.bookmark === true) {
                return { ...item, backgroundColor: "#9d4edd", color: "white" }
            }
            else if (item.isChecked === false) {
                return { ...item, backgroundColor: "#EC981A", color: "white" }
            }
            else if (item.isChecked === true) {
                return { ...item, backgroundColor: "#133382", color: "white" }
            }
            else {
                return { ...item, backgroundColor: "#e5e5e5", color: "#133382" }
            }
            // return item
        })
        setData(updateCurrentColor)

    }, [currentQuestion])

    // useEffect(() => {
    //     console.log('hii');

    //     const addCheck = data.map((item) => {
    //         if(prevQuestion === item.id && item.isChecked === false){
    //             console.log(item);
    //             return { ...item, backgroundColor: "yellow", color: "#133382" }
    //         }
    //         return item
    //     })
    //     setData(addCheck);      
    // }, [prevQuestion])

    const toggle = () => {
        setShowOverlay(true)
    }
    const close = () => {
        setShowOverlay(false);
    }

    console.log(questionBool);

    const pauseTimer = () => {
        clearInterval(timeInterval);
    }
    const handleFinish = () => {
        setFinish(!finish);
        pauseTimer()
    }
    const handleTryAgain = () => {
        setScore(scoreDataArr);
        setTime(time)
        setStart(!start);
        setFinish(!finish);
    }
    const [userResponseBool, setUserResponseBool] = useState(false)
    console.log(answers);
    useEffect(() => {
        if (userResponseBool) {
            const email = JSON.parse(localStorage.getItem('user')).id;
            http.post('/auth/getUserResponse', { answers, email }).then((res) => {
                console.log(res.data)
                const reportData = res.data.report[0];
                // setReport(reportData)
                console.log(reportData);
                const updatedScore = score.map((item) => {
                    const { count_attempts, total_marks, total_marks_correct, count_wrong, count_correct, count_skip, } = reportData
                    const count = data.filter((item) => {
                        return item.bookmark === true;
                    })
                    if (item.text === 'Total Marks of paper') {
                        const newSkipCount = { ...item, value: total_marks }
                        return newSkipCount
                    }
                    else if (item.text === 'Total Marks of correct ans') {
                        const newSkipCount = { ...item, value: total_marks_correct }
                        return newSkipCount
                    }
                    else if (item.text === 'Total attempts') {
                        const newSkipCount = { ...item, value: count_attempts }
                        return newSkipCount
                    }
                    else if (item.text === 'Total of Correct attempts') {
                        const newSkipCount = { ...item, value: count_correct }
                        return newSkipCount
                    }
                    else if (item.text === 'Total of Wrong attempts') {
                        const newSkipCount = { ...item, value: count_wrong }
                        return newSkipCount
                    }
                    else if (item.text === 'Total questions Skipped') {
                        const newSkipCount = { ...item, value: count_skip }
                        return newSkipCount
                    }
                    else if (item.text === 'Bookmark') {
                        const newBookCount = { ...item, value: count.length }
                        return newBookCount
                    }
                    return item
                })
                setScore(updatedScore);
            })
            setUserResponseBool(false);
            setAnswers([]);
        }
    }, [userResponseBool]);

    // const resetBackgrond = () => {
    //     const reset = data.map((item) => {

    //     })
    // }
    const handleSubmit = () => {
        setUserResponseBool(true);
        handleFinish()
        setData(data);
        // setAnswers([]);
    }

    useEffect(() => {
        if (flagVal.flag === 0 && location.pathname === '/pages/form') {
            navigate('/pages/dashboard')
            if (window.confirm('You will be logged out')) {
                setBool(true);
            }
        }
    }, [navigate])

    return (
        <OverlayContext.Provider
            value={{
                showOverLay, start, finish, data, transaction, latestTransaction, formData, time, setTime, popupState,
                bool, id, email, flag, flagVal, timeInterval, answers, loading, userResponseBool, score, currentQuestion,
                setScore, setTimeInterval, setBool, setFormData, setTransaction, setLatestTransaction, setData, handleStart,
                toggle, close, handleFinish, handleTryAgain, handleSubmit, setFinish, setFlag, setAnswers, handleQuestion,
                setUserResponseBool, showPopup, hidePopup, setCurrentQuestion
            }}
        >
            {children}
        </OverlayContext.Provider>
    )
}
