import { useContext, useEffect, useRef } from 'react';
import Api from '../API/Api';
import Time from '../components/Time';
import { OverlayContext } from '../context/OverlayContext';
import useLogout from './useLogout';

const time = 5 * 60;
export const useIdleLogout = (idleTime = time) => {
    const idleRef = useRef(0);
    const handleLogout = useLogout();
    const { getToken } = Api();
    const { start } = useContext(OverlayContext)
    useEffect(() => {
        if (getToken()) {

            const timerIncrement = () => {
                idleRef.current += 1;
                if (idleRef.current > idleTime) {
                    handleLogout();
                    clearInterval(idleInterval);
                }
            };

            const idleInterval = setInterval(timerIncrement, 1000);

            const resetIdleRef = () => {
                idleRef.current = 0;
            };

            if (!localStorage.getItem('currClickEvent')) {
                localStorage.setItem('currClickEvent', Time())
            }
            const clickEvent = (e) => {
                console.log(e);
                idleRef.current = 0;

                const currClickEvent = localStorage.getItem('currClickEvent').split(' ')[0]

                const newTime = Time().split(' ')[0];
                console.log(currClickEvent);
                console.log(newTime);
                const newDate1 = new Date(currClickEvent);
                const newDate2 = new Date(newTime);

                const diffTime = Math.abs(newDate2 - newDate1);

                console.log(diffTime);
                if (diffTime < 300000) {
                    localStorage.setItem('currClickEvent', Time());
                } else {
                    handleLogout();
                }

            }
            document.body.addEventListener('click', clickEvent);

            document.body.addEventListener('mousemove', resetIdleRef);
            document.body.addEventListener('mousedown', resetIdleRef);
            document.body.addEventListener('keypress', resetIdleRef);
            document.body.addEventListener('scroll', resetIdleRef);

            return () => {
                document.body.removeEventListener('mousemove', resetIdleRef);
                document.body.removeEventListener('mousedown', resetIdleRef);
                document.body.removeEventListener('keypress', resetIdleRef);
                document.body.removeEventListener('click', clickEvent);
                document.body.removeEventListener('scroll', resetIdleRef);
                clearInterval(idleInterval);
            };
        }
    }, [handleLogout, idleTime, start]);
};