import React, { useContext, useState } from 'react'
import QuizSingleQuest from './QuizSingleQuest';
import QuizIndex from './QuizIndex';
import { OverlayContext } from '../context/OverlayContext';

export default function QuizQuestions() {
    const { data, setData, handleSubmit, attempts } = useContext(OverlayContext);

    const [selectedOptions, setSelectedOptions] = useState({});
    const [question, setQuestion] = useState(1);
    console.log(data);
    console.log(selectedOptions);
    const [singleData] = data.filter((item) => {
        return item.id === question;
    });

    const handleBookmark = () => {
        const questId = singleData.id;
        const updatedBookMark = data.map((item) => {
            if (questId === item.id) {
                return { ...item, bookmark: !item.bookmark }
            }
            return item
        })
        setData(updatedBookMark)
    }
   
    console.log(singleData);
    const handleSelected = (e) => {
        const questId = singleData.id;
        const updatedSelectedOptions = { ...selectedOptions, [questId]: e.target.value };
        setSelectedOptions(updatedSelectedOptions);
    };

    console.log(attempts);
    const handleQuestions = (e) => {
        const { value } = e.target;
        const parsed = parseInt(value)
        setQuestion(parsed);
    }
    const handleNext = () => {
        const next = question + 1;
        setQuestion(next);
    }
    console.log(selectedOptions);
    return (
        <>
            <QuizSingleQuest
                singleData={singleData}
                selectedOptions={selectedOptions[singleData.id]}
                handleSelected={handleSelected}
                data={data}
            />
            <div className='my-4 flex '>
                <button onClick={handleNext} className='inline-block px-4 py-2 ml-4 my-2 text-white bg-pink rounded'>
                    Next
                </button>
                <button onClick={handleBookmark} className='inline-block px-4 py-2 ml-4 my-2 text-white bg-[#9d4edd] rounded'>
                    BookMark
                </button>
                <button onClick={handleSubmit} className='inline-block px-4 py-2 ml-4 my-2 text-white bg-blue rounded'>
                    Submit
                </button>
            </div>
            <QuizIndex data={data} handleQuestions={handleQuestions} question={question} />
        </>
    )
}
