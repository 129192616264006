import React, { useContext } from 'react'
import { OverlayContext } from '../context/OverlayContext';

export default function QuizIndex({ data, handleQuestions, question }) {
    const { setCurrentQuestion } = useContext(OverlayContext);

    return (
        <div className='w-full shadow-[0px_3px_12px] shadow-gray-300 rounded  p-4 mb-2 mt-2'>
            <div className='flex flex-wrap justify-center'>
                {data.map((item) => {
                    if (question === item.id) {
                        setCurrentQuestion(question)
                        
                    }

                    console.log(item.id);
                    return (
                        <button value={item.id} onClick={handleQuestions} className={`inline-block w-[30px] h-[35px] mx-6 my-4 rounded-md 
                         font-semibold  text-base `}
                            
                            style={{ backgroundColor: `${item.backgroundColor}`, color: `${item.color}` }}
                        >
                            {item.id}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
// ${question === item.id ? "bg-pink text-white" : "bg-[#e5e5e5] text-blue"}